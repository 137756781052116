import axios from '@axios'

export default function voteServices() {
  const voteCreate = async (queryParams) => {
    const res = await axios.post('/vote/create', queryParams)
    console.log(res)
    return res
  }

  const voteCheck = async (queryParams) => {
    const res = await axios.get('/vote/check', queryParams)
    console.log(res)
    return res
  }

  return {
    voteCreate,
    voteCheck,
  }
}
